import { TemplateUserDataModel } from "../../../modules/template/models/TemplateUserDataModel";

export const initTemplateUserData: TemplateUserDataModel = {
  logoShape: "circle",
  profile_image:  {
    filename_disk: "",
    id: "",
    filename_download: "",
  },
  company_logo:  {
    filename_disk: "",
    id: "",
    filename_download: "",
  },
  premium_plan: false,
  plan_expired_datetime: new Date(),
  firstName: "",
  lastName: "",
  designation: "",
  selected_plan: 1,
  isCompanyName: false,
  companyName: "",
  email: "",
  mobileNumber: undefined,
  websiteLink: "",
  address: "",
  description: "",
  isCompanyLogo: true,
  isProfileImage: true,
  isEmail: false,
  isWebsite: false,
  isWhatsapp: true,
  isFacebook: false,
  isInstagram: false,
  isTwitter: false,
  isYouTube: false,
  whatsAppLink: "",
  facebookLink: "",
  instagramLink: "",
  twitterLink: "",
  youTubeLink: "",
  service1: undefined,
  service2: undefined,
  service3: undefined,
  service4: undefined,
  services: [],
  servicesCount: 0,
  selectedTemplateID: 6,
  id: undefined,
  industry: undefined,
  templateColor: "#234170",
  templateSecondaryColor: "#f4f7fc",
  status: "Draft",
  croppedAreaProfile: '{"x":0,"y":0,"width":100,"height":100}',
  croppedAreaLogo: '{"x":0,"y":0,"width":100,"height":100}',
};
