
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { QRCodeSVG } from "qrcode.react";
import { ASSEST_URL, PUBLIC_URL, RAZORPAY_ENABLED } from "../app/helper/EnvVar";

interface PrintableModalProps {
    showModal: boolean;
    handleCloseModal: () => void;
    templateUserData: any; 
    handlePrint: () => void;
}
const PrintableModal: React.FC<PrintableModalProps> = ({ showModal, handleCloseModal, templateUserData, handlePrint }) => {
    return (
        <Modal show={showModal} onHide={handleCloseModal} dialogClassName="centered-modal" contentClassName="rounded-modal">
            <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title>My Pocketsite QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="printContent">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%" }}>
                        <div style={{ color: templateUserData?.templateColor, fontSize: "24px" }}>
                            {templateUserData?.firstName} {templateUserData?.lastName}
                        </div>
                        <QRCodeSVG
                            value={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}
                            width="200"
                            height="200"
                            style={{ marginTop: "20px" }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                <Button variant="primary" className="btn btn-success" onClick={handlePrint}>
                    Print
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PrintableModal;