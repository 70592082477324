import AOS from "aos";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../../node_modules/aos/dist/aos.css";
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
AOS.init();
function SpecialDesign() {
  const { auth , templateUserData} = useSelector((state: any) => state);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000)
    return () => clearTimeout(timer);
  }, [])
  return (
    <>
      <div className="special-desing-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
            {loading ? (
                <Skeleton height={64} width={600} style={{ marginTop: "30px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <h2 className="heading-medium">Special designs for you</h2>
            }
            {loading ? (
                <Skeleton height={20} width={300} style={{ marginTop: "15px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <p className="mb-40">Business Cards of your choice</p>
            }
            </div>

            <div className="col-md-4" data-aos="zoom-out-right">
            {loading ? (
                <Skeleton height={566} width={356} style={{ marginTop: "40px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <Link
                className="img-box"
                to={
                  auth.isAuthorized
                    ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}`
                    : "/auth/login"
                }
              >

                <img
                  src="assets/images/special01.webp"
                  alt="special01"
                  width="394"
                  height="666"
                />
              </Link>
}
            </div>
            <div className="col-md-4">
            {loading ? (
                <Skeleton height={566} width={356} style={{ marginTop: "40px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <Link
                className="img-box"
                to={
                  auth.isAuthorized
                    ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}`
                    : "/auth/login"
                }
              >
                <img
                  src="assets/images/special02.webp"
                  alt="special01"
                  width="394"
                  height="666"
                />
              </Link>
}
            </div>
            <div className="col-md-4" data-aos="zoom-out-left">
            {loading ? (
                <Skeleton height={566} width={356} style={{ marginTop: "40px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <Link
                className="img-box"
                to={
                  auth.isAuthorized
                    ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}`
                    : "/auth/login"
                }
              >
                <img
                  src="assets/images/special03.webp"
                  alt="special01"
                  width="394"
                  height="666"
                />
              </Link>
}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpecialDesign;
