import React, { useEffect, useRef, useState } from 'react'
import Buttons from '../../../../../../../components/Buttons';
import { Modal } from 'react-bootstrap-v5';
import AvatarEditor from 'react-avatar-editor';
import { Slider } from '@mui/material';
import { dataURLtoFile } from './utils/cropImage';
import { ResizeImage } from './utils/ResizeImage';
import { addFiles } from '../../../../../../modules/template/redux/TemplateUserDataCRUD';
import Loading from '../../../../../../../_start/helpers/components/Loading';
import { ASSEST_URL } from '../../../../../../helper/EnvVar';

type Props = {
    setLogoShape?: ((shape: any) => void) | undefined;
    setLoader?: ((value: boolean) => void) | undefined;
    logoShape?: "circle" | "circular" | "rounded" | "square" | undefined;
    existLogoShape?: "circle" | "circular" | "rounded" | "square" | undefined;
    file?: any;
    show?: boolean;
    setPreview?:any;
    preview?: any;
    setModalOpen?:any;
    src?: any; 
    setSrc1?: any; 
    uploadCompanyLogoFile?: any;
    cropperCompanyImageInput?: string | File;  
    setCompanyLogoIsLoading?: any;
    existCompanyImage?: any;
    existCompanyImageName?: any;
    setChangeImageEvent?: any;
    changeEvent?: any;
    loader?: any;
    companyLogoIsLoading?: any;
    onCropped: (
      img: string,
      imgFor: string,
      croppedImgArea: any,
      cropFile: any,
      cropUrl: any,
      name: any,
    ) => void;
  };

const CropCompanyLogo = ({
    show = false,
    setLogoShape,
    logoShape,
    existLogoShape,
    file,
    setModalOpen, 
    setPreview, 
    preview,
    src,
    setSrc1,
    loader,
    setLoader,
    companyLogoIsLoading,
    setCompanyLogoIsLoading,
    cropperCompanyImageInput,
    onCropped,
    existCompanyImageName,
    setChangeImageEvent,
  }: Props) => {
    
    const cropRef = useRef<any>(null);
    const [image, setImage] = useState<any>()
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels,] = useState(null);
    const [aspect, setAspect] = useState(existLogoShape === 'circle' ? 50 : 0);
    const [cropShape, setCropShape] = useState<number>(0);
    
    useEffect(()=>{
      if(logoShape === 'circle'){
        setAspect(50)
      }else{
        setAspect(0)
      }
    },[logoShape])
    
    const convertBlob = async () => {
      const result = await fetch(`${ASSEST_URL}/${cropperCompanyImageInput}`);
      const blob = await result.blob(); 
      setImage(URL.createObjectURL(blob)) 
      if(setLogoShape) {
        setLogoShape(aspect === 0 ? "square" : "circle");
      }
    }

    useEffect( () => {
      convertBlob()
      // handleEmptyView()
    },[cropperCompanyImageInput])

    useEffect(()=>{
      // custom comment
    },[aspect])
    
    // const handleEmptyView = async () => {
    //   if(preview === null && src === ''){
    //       const dataUrl = cropRef?.current?.getImage()?.toDataURL();
    //       // const processDataUrl = await preprocessImage(dataUrl);
    //       const result = await fetch(dataUrl);
    //       const blob = await result.blob(); 
    //       setImage(URL.createObjectURL(blob));
    //       if(setLogoShape) {
    //         setLogoShape(aspect === 0 ? "square" : "circle");
    //       }
    //   } 
    // }
  
    //handle save
    const handleSave = async () => {
      if (cropRef) {
        const dataUrl = cropRef?.current?.getImage()?.toDataURL();
        const result = await fetch(dataUrl);
        const blob = await result.blob();
        // setPreview(URL.createObjectURL(blob));
        setImage(URL.createObjectURL(blob));
        setModalOpen(false);
        setChangeImageEvent(false);
        setZoom(1); 
        if(setLogoShape) {
            setLogoShape(aspect === 0 ? "square" : "circle");
        }
        var nFile = dataURLtoFile(dataUrl, file?.name ? file?.name : existCompanyImageName );  
        const reSizeImage = await ResizeImage(nFile).then((res: any) => res);
        const resizeImageFormat = dataURLtoFile(reSizeImage, file?.name ? file?.name : existCompanyImageName);
        const data = await addFiles(resizeImageFormat).then(result => result?.data);
        onCropped!(dataUrl, "companyLogo", croppedAreaPixels!, blob, data, nFile); 
      }
    };
  
    const handleAvtarChangeImage = async () => {
      const dataUrl = cropRef?.current && cropRef?.current?.getImage()?.toDataURL() && cropRef?.current?.getImage()?.toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      // setPreview(URL.createObjectURL(blob));  
      setCropShape(cropShape);
      if (setLogoShape) {
        setLogoShape(aspect === 0 ? "square" : "circle");
      }
    }
  
    const onZoomChange = (zoom: any) => {
      setZoom(zoom);
      handleAvtarChangeImage();
    };
    
    const onRotation = (rotation: any) => {  
      setRotation(rotation);
      handleAvtarChangeImage();
    };
  
    const handleCancel = () => {
      setModalOpen(false);    
      setChangeImageEvent(false);
      setCompanyLogoIsLoading(false);
      // setPreview(image)
      setSrc1(image)
      setZoom(1);
    }
  
    const handleChangeInputFile = async () => {
      setChangeImageEvent(true)
      var inp;
      inp = document.getElementById('uploaderCompanyLogo');
      inp?.click();
      if(setLoader){
        setLoader(false)
      }
    }
  
    return (
      <div className="overflow-hidden">
      <Modal
        className="modal-sticky modal-sticky-lg review-modal"
        id="kt_inbox_compose01"
        role="dialog"
        data-backdrop="false"
        aria-hidden="true"
        tabIndex="-1"
        show={show}
        onHide={()=> {}}
        animation={true}
      >
        {/*begin::Body*/}
        <div className="modal-body">
          {/*begin::Message*/}
          <div className="d-flex flex-column">
            <div className="master-container">
              <div className="crop-container">
                  {loader === true ? <span className="crop-content-center">
                    <Loading />
                  </span> : <AvatarEditor
                    id='avatar-company-logo'
                    ref={cropRef}
                    className="crop-content-center"
                    image={src ? src : image} 
                    width={aspect === 50 && logoShape === 'circle' ? 300 : 230}
                    height={aspect === 50 && logoShape === 'circle' ? 100 : 200}
                    border={2}
                    color={[118, 116, 127, 0.43]} // RGBA
                    scale={zoom}
                    rotate={rotation}
                    onMouseMove={handleAvtarChangeImage}
                  />}
              </div>
              <div className="controls">
                <div className="handle">
                  <span className="labels mb-2 d-flex"> Zoom : </span>
                  <Slider
                    value={zoom}
                    min={0.1}
                    max={3}
                    step={0.01}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => onZoomChange(zoom)}
                  />
                </div>
                <div className="handle">
                  <span className="labels mb-2 d-flex"> Rotation : </span>
                  <Slider
                    valueLabelDisplay="auto"
                    min={0}
                    max={360}
                    value={rotation}
                    onChange={(e: any, rotation: any) => onRotation(rotation)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*end::Message*/}
        </div>
  
        {/*end::Body*/}
        {/*begin::Footer*/}
        <div className="py-4 container border-top">
          <div className="row align-items-end flex-sm-row flex-column">
            <div className="col">               
                  <div className="row">
                  <div className="col-6">
                    <button
                      onClick={() => {
                        setAspect(0);
                        setLogoShape!("square");
                      }}
                      className="btn btn-primary  me-3 mb-3 w-100"
                    >
                      Square
                    </button>
                  </div>
                  <div className="col-6">

                  <button
                    onClick={() => {
                      setAspect(50);
                      // For rectangle shape use 'circle' value
                      setLogoShape!("circle");
                    }}
                    className="btn btn-primary mb-3 w-100"
                  >
                    Rectangle
                  </button>
                  </div>
                </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center w-100">
                <button
                  onClick={() => handleChangeInputFile()}
                  className="btn btn-primary w-100 mb-3"
                >
                  Change Image
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center w-100">
                <button onClick={handleCancel} className="btn cta w-100">
                  Cancel
                </button>
              </div>
            </div>
  
            <div className="col">
              <div className="d-flex align-items-center w-100">
                <Buttons
                  btnText={"Save"}
                  buttonName={"button"}
                  className="btn btn-primary w-100"
                  onClick={handleSave}
                ></Buttons>
              </div>
            </div>
          </div>
        </div>
        {/*end::Footer*/}
      </Modal>
    </div>
    )
  }

export default CropCompanyLogo