import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Buttons from "../Buttons";
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
function MobilityFinest() {
  const { auth , templateUserData} = useSelector((state: any) => state);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000)
    return () => clearTimeout(timer);
  }, [])
  return (
    <>
      <div className="content-image-section bg-light-blue mobile-finet-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
            {loading ? (
                <Skeleton height={64} width={636} style={{ marginTop: "30px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <h2 className="heading-medium fs-24">Mobility at its finest</h2>
            }
            {loading ? (
                <Skeleton height={288} width={636} style={{ marginTop: "30px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <p className="mb-40">
                Experience the ultimate mobility with our platform's seamless
                profile sharing system. Effortlessly share your professional
                profile with just a few taps, whether it's through social media,
                email, or saving it as a convenient vCard. Connect with
                potential clients or employers on the go, ensuring your profile
                is always within reach. Embrace the freedom of mobility and
                expand your network with ease, unlocking new opportunities
                wherever you are. Elevate your professional presence with our
                hassle-free sharing features and stay connected in today's
                fast-paced world.
              </p>
}
{loading ? (
                <Skeleton height={48} width={153} style={{ marginTop: "40px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <Link
                to={
                  auth.isAuthorized
                    ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}`
                    : "/auth/login"
                }
              >
                <Buttons
                  btnText={"Create Yours"}
                  buttonName={"button"}
                  className="btn btn-primary px-4"
                ></Buttons>
              </Link>
              }
            </div>
            <div className="col-lg-6 text-center order-1 order-lg-2 mb-4 mb-lg-0 text-center">
              <div className="img-col.mobile-finet-section">
              {loading ? (
                <Skeleton height={483} width={323} style={{ marginTop: "30px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
                <img
                  src="assets/images/finest-img.webp"
                  alt="finest-img"
                  width="323"
                  height="483"
                />
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobilityFinest;
