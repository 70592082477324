import React from "react";
import Tour from "reactour";

type Props = {
  isTourOpen: any;
  setIsTourOpen: any;
  setCurrentStep: any;
};

function TourPremiumFeatures({
  isTourOpen,
  setIsTourOpen,
  setCurrentStep,
}: Props) {

  const steps = [
    {
      selector: ".logo-bg-shape",
      content: ({goTo}: any) => (
        <>
           <span>You can get <b className="plans-text-color">Company Logo</b> on your cards choosing premium plan</span>
           <span className="d-none">
            {setTimeout(()=>{
                    goTo(1)
                },3500)}            
            </span>
        </>
      ),
    },
    {
      selector: ".user-post",
      content: ({goTo}: any) => (
        <>
          <span>You can get <b className="plans-text-color">Designation</b> on your cards choosing premium plan</span>
            <span className="d-none">
            {setTimeout(()=>{
                goTo(2)
            },3500)}
          </span>
        </>
      ),
    },
    {
      selector: ".company-name ",
      content: ({goTo}: any) => (
        <>
           <span>You can get <b className="plans-text-color">Company Name</b> on your cards choosing premium plan</span>
           <span className="d-none">
            {setTimeout(()=>{
                goTo(0)
            },3500)}
          </span></>
      )
    },
  ];

  return (
    <>
      <Tour
        steps={steps}
        isOpen={isTourOpen}        
        accentColor="#0aaec1"
        onRequestClose={() => setIsTourOpen(false)}
        rounded={8}
        badgeContent={(curr: any, tot: any) => {
          setCurrentStep(curr);
          return `${curr} of ${tot} | ${curr === 1 ? "Company Logo" : ""} ${
            curr === 2 ? "Designation" : ""
          } ${curr === 3 ? "Company Name" : ""}`;
        }}
      />
    </>
  );
}

export default TourPremiumFeatures;
