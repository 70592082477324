import React, { useEffect } from "react"; 
import Navbar from "react-bootstrap/Navbar";
import SVG from "react-inlinesvg"; 
import "../scss/header.scss";
import userIcon from "../templates/images/icons/user-icon.svg";
import cardIcon from "../templates/images/icons/cards-icon.svg";
import logout from "../templates/images/icons/logout-icon.svg";
import { useSelector } from "react-redux";
import { RootState } from "../setup";
import { Link, useParams } from "react-router-dom"; 
import { toast } from "react-toastify";

function Sidebarnav() {
  const params = useParams()
  const templateUserData = useSelector(
    (state: RootState) => state.templateUserData.templateUserData!
  );

  useEffect(() => {
    
  },[templateUserData?.selectedTemplateID])

  const handleDisabledCardsTab = () => {
    toast.warning("Please fill the information", {
      autoClose: 3500
    })
  }

  return (
    <Navbar className="sidebar-nav">
      <div className="top-header">
        <span className="logo">
          <Link to="/home" target ="_self" title="Home">
            <img
              src="/media/logos/logonew.png"
              alt="logonew"
              width={80}
              height={60}
            />
          </Link>
        </span>
        <ul className="side-items">
          {/* <li className="item">
            <Nav.Link href="/dashboard" className="pt-0">
              <span className="icon">
                <SVG src={dashboardIcon} width={20} height={20} />
              </span>
              Dashboard
            </Nav.Link>
          </li> */}
          <li className="item">
            <Link
              to={`/edit-template/${templateUserData?.selectedTemplateID}`}
              className={`pt-0 dashboard-links ${params?.id ? "active" : ""}`}
            >
              <span className="icon">
                <SVG src={userIcon} />
              </span>
              Profile
            </Link>
          </li>
          <li className="item">
            {templateUserData?.status === "Published" ? (
              <Link
                to="/profile/preview"
                className={`pt-0 dashboard-links ${
                  !params?.id ? "active" : ""
                }`}
              >
                <span className="icon">
                  <SVG src={cardIcon} width="20" height={20} />
                </span>
                Cards
              </Link>
            ) : (
              <span
                className={`pt-0 dashboard-links ${
                  !params?.id ? "active" : ""
                }`}
                onClick={handleDisabledCardsTab}
              >
                <span className="icon">
                  <SVG src={cardIcon} width="20" height={20} />
                </span>
                Cards
              </span>
            )}
          </li> 
        </ul>
      </div>
      <ul className="side-items logout">
        <li className="item">
          <Link to="/logout" className="pt-0 dashboard-links">
            <span className="icon">
              <SVG src={logout} width="20" height={20} />
            </span>
            Logout
          </Link>
        </li>
      </ul>
    </Navbar>
  );
}

export default Sidebarnav;
