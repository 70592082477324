import React from "react";
import SVG from "react-inlinesvg";
import circleRight from "../templates/images/icons/circleRight.svg";

type Props = {
  views: any;
  plans: any;
  premiumViews: any;
  handleToggleChange: (e: any) => void;
  handleBasicPlan: () => void;
  handlePremiumPlan: () => void;
};

function DesktopViewPlans({
  views,
  premiumViews,
  handleBasicPlan,
  handlePremiumPlan,
  plans,
}: Props) {
  return (
    <>
      <div className="row">
        {plans &&
          plans?.map((res: any) => (
            <div className="col-lg-6 d-flex">
              <div className="card mb-5 mb-lg-0 w-100 position-relative">
                <div className="ribbon ribbon-top-left">
                  <span className="card-price text-center">
                    ₹ {res?.plan_price}
                    <label className="period ps-1"> / year</label>
                  </span>
                </div>
                <div className="card-body rounded-bordered">
                  <h6 className="card-title text-uppercase text-center plans-text-color pt-2 pb-3">
                    {res?.plan_name}
                  </h6> 

                  {res?.plan_name === "Free" ? views : premiumViews }

                  <div className="show-detail-wrap">
                    <div className="show-detail">  
                      <div className="d-grid">
                        {res?.plan_name === "Free" ? (
                          <button
                            type="button"
                            className="btn btn-primary text-uppercase"
                            onClick={handleBasicPlan}
                          >
                            Continue with basic plan
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary text-uppercase"
                            onClick={handlePremiumPlan}
                          >
                            Upgrade Your plan
                          </button>
                        )}
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default DesktopViewPlans;
