import * as Yup from "yup";
import { mobNum, urlRegExp } from "../../../helper/helper";

export const TemplateValidate = Yup.object().shape(
  {
    profile_image: Yup.mixed().test('isStringOrObject', 'Profile Image must be a string or an object', value => {
      return Yup.string().isValidSync(value) || Yup.object().isValidSync(value);
    }).nullable().required("Profile Image Required"),
    isCompanyLogo: Yup.boolean(),
    company_logo: Yup.mixed().test('isStringOrObject', 'Please Select Company Logo, or Disable it.', function (value) {
      const { isCompanyLogo } = this.parent;
      if (isCompanyLogo) {
        return Yup.string().isValidSync(value) || Yup.object().isValidSync(value);
      }
      return true;
    }).nullable(),
    firstName: Yup.string()
      .min(2, "Minimum 2 Character")
      .max(20, "Maximum 20 Characters")
      .required("First Name is required").nullable(),
    lastName: Yup.string()
      .min(2, "Minimum 2 Character")
      .max(20, "Maximum 20 Character")
      .required("Last Name is required").nullable(),
    companyName: Yup.string()
      .min(5, "Minimum 5 Character are Required")
      .max(80, "Maximum 80 Character are Required")
      .when("isCompanyName", {
        is: (value: boolean) => value,
        then: (rule) => rule.required("Company Name is Required"),
      })
      .nullable(),
    designation: Yup.string()
      .min(2, "Minimum 2 Character")
      .max(50, "Maximum 50 Character")
      .required("Designation is Required").nullable(),
    email: Yup.string()
      .email("Wrong email format")
      .when("isEmail", {
        is: (value: boolean) => value,
        then: (rule) => rule.required("Email is Required"),
      })
      .required("Email is Required")
      .nullable(),
    mobileNumber: Yup.string()
      .min(
        10,
        "Please enter a valid 10 digit mobile number, without special characters and spaces"
      )
      .required("Mobile number is required")
      .matches(
        mobNum,
        "Mobile number is not valid Cannot contain special characters or spaces"
      ),
    websiteLink: Yup.string()
      .when("isWebsite", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.required("Website Link is Required")
      })
      .matches(urlRegExp, "Enter Qualified Website Link! e.g example.com")
      .nullable(),
    address: Yup.string()
      .min(10, "Minimum 10 Character")
      .max(150, "Maximum 150 Character")
      .required("Address is Required").nullable(),
    description: Yup.string()
      .min(30, "Minimum 30 Character")
      .max(150, "Maximum 150 Character")
      .required("Description is Required").nullable(),
    whatsAppLink: Yup.string()
      .min(
        10,
        "Please enter a valid 10 digit mobile number, without special characters and spaces"
      )
      .required("Whatsapp number is required")
      .matches(
        mobNum,
        "Mobile number is not valid, Cannot contain special characters or spaces"
      ).nullable(),
    facebookLink: Yup.string()
      .when("isFacebook", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.required("Facebook Link is Required"),
      }).when("isFacebook", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule
          .matches(urlRegExp, "Enter correct url!"),
      })
      .nullable(),
    instagramLink: Yup.string()
      .when("isInstagram", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.required("Instagram Link is Required"),
      }).when("isInstagram", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.matches(urlRegExp, "Enter correct url!"),
      })
      .nullable(),
    twitterLink: Yup.string()
      .when("isTwitter", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.required("Twitter Link is Required"),
      }).when("isTwitter", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.matches(urlRegExp, "Enter correct url!"),
      })
      .nullable(),
    youTubeLink: Yup.string()
      .when("isYouTube", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.required("Youtube Link is Required"),
      }).when("isYouTube", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.matches(urlRegExp, "Enter correct url!"),
      })
      .nullable(),
    isFacebook: Yup.boolean(),
    isInstagram: Yup.boolean(),
    isTwitter: Yup.boolean(),
    isYouTube: Yup.boolean(),
  },
  [
    // .matches(urlRegExp, "Enter correct url!")
    // Add Cyclic deps here because when require itself
    ["isEmail", "isWebsite"],
  ]
);