import React from "react";
import SVG from "react-inlinesvg";
import close from "../templates/images/icons/close.svg";
import Plans from "../pages/Plans";

type Props = {
  setPaymentStatus: (value: any) => void;
  setLoading: (value: any) => void;
  paymentStatus: boolean;
  templateId: number;
  uploadProfileImageFile: string;
  uploadCompanyLogoFile: string;
  setShowPremiumModal: (value: any) => void;
  showPremiumModal: boolean;
};

function PremiumModal({
  setPaymentStatus,
  paymentStatus,
  setLoading,
  templateId,
  uploadCompanyLogoFile,
  uploadProfileImageFile,
  setShowPremiumModal,
  showPremiumModal
}: Props) {

  return (
    <>
      <div className="w-100">
        <button
          className="btn color-close"
          type="button"
          onClick={() => setShowPremiumModal(!showPremiumModal)}
        >
          <SVG src={close} />
        </button>
        <Plans
          setLoading={setLoading}
          setPaymentStatus={setPaymentStatus}
          paymentStatus={paymentStatus}
          templateId={templateId}
          uploadCompanyLogoFile={uploadCompanyLogoFile}
          uploadProfileImageFile={uploadProfileImageFile}
        />
      </div>
    </>
  );
}

export default PremiumModal;
