import { Avatar, Slider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { Modal } from "react-bootstrap-v5";
import Buttons from "../../../../../../../components/Buttons";
import { dataURLtoFile } from "./utils/cropImage";
import { ResizeImage } from "./utils/ResizeImage";
import { addFiles } from "../../../../../../modules/template/redux/TemplateUserDataCRUD";
import { ASSEST_URL } from "../../../../../../helper/EnvVar";

type Props = {
  photoURL?: string;
  setOpenCrop?: any;
  show?: boolean;
  file?: any;
  cropperImageInput?: string | File;
  cropperProfileImageInput?: string | File;
  setUploadProfileImageFile?: any;
  uploadProfileImageFile?: any;
  setCropperProfileImageInput?: any;
  setShowCropperFor?: any;
  setShowCroper?: any;
  profileImageIsLoading?: any;
  setProfileImageIsLoading?: any;
  closeCropper?: any;
  base64String?: any;
  handleFileUpload?: any;
  existProfileImage?: any;
  existProfileImageName?: any;
  setChangeImageEvent?: any;
  changeEvent?: any;
  setPreview?: any;
  preview?: any;
  setModalOpen?: any;
  src?: any;
  setSrc?: any;
  onCropped?: (
    img: string,
    imgFor: string,
    croppedImgArea: any,
    cropFile: any,
    cropUrl: any,
    name: any
  ) => void;
};

const CropProfileImage = ({
  src,
  setSrc,
  file,
  show,
  setModalOpen,
  setPreview,
  setChangeImageEvent,
  preview,
  setProfileImageIsLoading,
  cropperProfileImageInput,
  existProfileImageName,
  onCropped,
}: Props) => {
  const cropRef = useRef<any>(null);
  const [image, setImage] = useState<any>();
  const [zoom, setZoom] = useState(1.3);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels] = useState(null);

  const convertBlob = async () => {
    const result = await fetch(`${ASSEST_URL}/${cropperProfileImageInput}`);
    const blob = await result.blob(); 
    setImage(URL.createObjectURL(blob)) 
  }

  useEffect(() => {
    if(cropperProfileImageInput){
      convertBlob()
    }
    // else{
    //   setPreview(src)
    // }
  }, [cropperProfileImageInput]);

  //handle save
  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef?.current?.getImage()?.toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setPreview(URL.createObjectURL(blob));
      setImage(URL.createObjectURL(blob));
      setModalOpen(false);
      setChangeImageEvent(false);
      setZoom(1.3);
      var nFile = dataURLtoFile(
        dataUrl,
        file?.name ? file?.name : existProfileImageName
      );
      const reSizeImage = await ResizeImage(nFile).then((res: any) => res);
      const resizeImageFormat = dataURLtoFile(
        reSizeImage,
        file?.name ? file?.name : existProfileImageName
      );
      const data = await addFiles(resizeImageFormat).then(
        (result) => result?.data
      );
      onCropped!(
        dataUrl,
        "profileImage",
        croppedAreaPixels!,
        blob,
        data,
        nFile
      );
    }
  };

  const handleAvtarChangeImage = async () => {
    const dataUrl =
      cropRef?.current &&
      cropRef?.current?.getImage()?.toDataURL() &&
      cropRef?.current?.getImage()?.toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();
    setPreview(URL.createObjectURL(blob));
  };

  const onZoomChange = (zoom: any) => {
    setZoom(zoom);
    handleAvtarChangeImage();
  };

  const onRotation = (rotation: any) => {
    setRotation(rotation);
    handleAvtarChangeImage();
  };

  const handleCancel = () => {
    setModalOpen(false);
    setChangeImageEvent(false);
    setProfileImageIsLoading(false);
    setPreview(image);
    setSrc(image);
    setZoom(1.3);
    setRotation(0)
  };

  const handleChangeInputFile = () => {
    setChangeImageEvent(true);
    var inp;
    inp = document.getElementById("uploaderProfile");
    inp?.click();
    // handleAvtarChangeImage();
  };

  return (
    <div className="overflow-hidden">
      <Modal
        className="modal-sticky modal-sticky-lg review-modal"
        id="kt_inbox_compose01"
        role="dialog"
        data-backdrop="false"
        aria-hidden="true"
        tabIndex="-1"
        show={show}
        onHide={() => {}}
        animation={true}
      >
        {/*begin::Body*/}
        <div className="modal-body">
          {/*begin::Message*/}
          <div className="d-flex flex-column">
            <div className="master-container">
              <div className="crop-container">
                <AvatarEditor
                  ref={cropRef}
                  className="crop-content-center"
                  image={src ? src : image }
                  width={200}
                  height={200}
                  border={0}
                  style={{
                    borderColor: "#76747f",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderRadius: "100px",
                  }}
                  borderRadius={100}
                  color={[255, 255, 255, 255]} // RGBA
                  scale={zoom}
                  rotate={rotation}
                  onMouseMove={handleAvtarChangeImage}
                />
              </div>
              <div className="controls">
                <div className="handle">
                  <span className="labels mb-2 d-flex"> Zoom : </span>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.01}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => onZoomChange(zoom)}
                  />
                </div>
                <div className="handle">
                  <span className="labels mb-2 d-flex"> Rotation : </span>
                  <Slider
                    valueLabelDisplay="auto"
                    min={0}
                    max={360}
                    value={rotation}
                    onChange={(e: any, rotation: any) => onRotation(rotation)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*end::Message*/}
        </div>

        {/*end::Body*/}
        {/*begin::Footer*/}
        <div className="py-4 container border-top">
          <div className="row align-items-end flex-sm-row flex-column">
            <div className="col">
              <div
                className="row align-items-center"
                style={{ paddingBottom: "10px" }}
              >
                <div className="col">
                  <Avatar
                    src={preview ? preview : image}
                    variant="circular"
                    style={{ width: "90px", height: "90px" }}
                  />
                </div>
                <div className="col text-sm-start text-center">
                  <Avatar
                    className="mx-sm-0 mx-auto"
                    src={preview ? preview : image}
                    variant="circular"
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>
                <div className="col">
                  <Avatar
                    src={preview ? preview : image}
                    variant="circular"
                    style={{ width: "35px", height: "35px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center w-100">
                <button
                  onClick={() => handleChangeInputFile()}
                  className="btn btn-primary w-100 mb-3"
                >
                  Change Image
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center w-100">
                <button onClick={handleCancel} className="btn cta w-100">
                  Cancel
                </button>
              </div>
            </div>

            <div className="col">
              <div className="d-flex align-items-center w-100">
                <Buttons
                  btnText={"Save"}
                  buttonName={"button"}
                  className="btn btn-primary w-100"
                  onClick={handleSave}
                ></Buttons>
              </div>
            </div>
          </div>
        </div>
        {/*end::Footer*/}
      </Modal>
    </div>
  );
};

export default CropProfileImage;
