import Skeleton from "react-loading-skeleton";
import logo from "../../../../templates/images/logo.svg";

export function FallbackView() {
  return (
    <div className="splash-screen">
      <img
        src={logo}
        alt="pocketsite.me"
        width={72}
        height={72}
      />
      <span>Loading ...</span>
    </div>
  );
}