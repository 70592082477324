import React from "react";
import '../scss/toggle-switch.scss'

type Props = {
  name: string;
  handleChange: (e: any) => void
  checked: boolean | undefined;
  value: any
};

function ToggleSwitch({ name, handleChange, checked, value }: Props) {
  return (
    <div className="switch-wrapper-plan">
      <input id="free" type="radio" name={name} checked={value === "Free"} value={"Free"} onChange={handleChange} />
      <input id="premium" type="radio" name={name} checked={value === "Premium"} value={"Premium"} onChange={handleChange} /> 
      <label htmlFor="free">Free</label>
      <label htmlFor="premium">Premium</label> 
      <span className="highlighter"></span>
    </div>
  );
}

export default ToggleSwitch;
