import { useFormik } from "formik";
import { nanoid } from "nanoid";
import React, { lazy, useEffect, useState } from "react";
import { CirclePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { colors } from "../app/helper/constant";
import { ASSEST_URL } from "../app/helper/EnvVar";
import { TemplateUserDataModel } from "../app/modules/template/models/TemplateUserDataModel";
import { addTemplateUserData, getTemplateUserDataByUserID } from "../app/modules/template/redux/TemplateUserDataCRUD";
import * as templateUserDataRedux from "../app/modules/template/redux/TemplateUserDataRedux";
import * as template from "../app/modules/template/redux/TemplateListRedux";
import { initTemplateUserData } from "../app/pages/templates/helper/InitTemplateData";
import Buttons from "../components/Buttons";
import ShareModal from "../components/ShareModal";
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "../scss/profile-preview.scss";
import "../scss/preview-page.scss";
import { RootState } from "../setup";
import { firstCharCapital } from "../app/helper/helper";

type Item = {
  id: string;
  image: {
    filename_disk: string;
  };
  name: string;
};

const importView = (tempId: number) =>
  lazy(() =>
    import(`../templates/Template0${tempId}`).catch(() => {
      import(`../app/pages/NotFound/NotFoundComponent`);
    })
  );

const ProfilePreview = () => {
  const navRef = React.useRef<any>();
  const prevToggleRef = React.useRef<any>();
  const [modalShow, setModalShow] = React.useState(false);
  const [showApplyButton, setShowApplyButton] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [views, setViews] = useState<any>();
  const [templateId, setTemplateID] = useState<any>();
  const [tempColor, setTemplateColor] = useState<any[]>([]);
  // const [currentColor, setCurrentColor] = useState<string>('');
  const [, setTemplateLightColor] = useState<any>();

  const [, setCurTemp] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showShareButton, setShowShareButton] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onToggleClick = () => {
    navRef.current.classList.toggle("show-color-picker");
  };
  const onTemplatePrvToggle = () => {
    prevToggleRef.current.classList.toggle("show-preview");
    document.body.classList.toggle("overflow-hidden");
  };

  useEffect(() => {
    setTimeout(() => {
      if (templateId) {
        setShowShareButton(true);
      }
    }, 500);
  }, [templateId]);

  const {
    template: { templateList },
  } = useSelector((state: any) => state);

  const root = document.documentElement;
  let secondaryColor: string;
  const templateUserData: TemplateUserDataModel = useSelector(
    (state: RootState) => state.templateUserData.templateUserData!
  );
  const isAuthorized: boolean = useSelector(
    (state: RootState) => state.auth.isAuthorized!
  );

  useEffect(() => {
    dispatch(template.action.getTemplateList());
    dispatch(templateUserDataRedux.actions.getServicesList(""));
    dispatch(templateUserDataRedux.actions.getSetTemplateUserData());
  }, []);

  useEffect(() => {
    setTemplateColors();
  }, [templateId, templateList]);

  const setTemplateColors = () => {
    templateList?.map((template: any) => {
      if (template?.id === templateId) {
        setTemplateColor(template?.templateColor);
        setTemplateLightColor(template?.templateSecondaryColor);
      }
      return "";
    });
  };

  async function loadViews(tempId: number) {
    setCurTemp(tempId);
    setTemplateID(tempId);
    setIsLoading(true);
    const currentColor = await getTemplateColorFromAPI();
    const componentPromises = async (tempId: number) => {
      const View = await importView(tempId);
      if (tempId !== templateUserData?.selectedTemplateID || currentColor !== templateUserData.templateColor) {
        setShowApplyButton(true);
      } else {
        setShowApplyButton(false);
      }
      const naid: string = nanoid(10);
      return <View key={naid} editMode={false} skeletonLoading={isLoading} setSkeletonLoading={setIsLoading} reacTour={false} />;
    };
    componentPromises(tempId).then(setViews);
  }

  const getTemplateColorFromAPI = async () => {
    const currentColor = await getTemplateUserDataByUserID()
    return currentColor?.data?.data[0]?.templateColor;
  }  

  useEffect(() => {
    if (templateId) {
      if (templateId !== templateUserData?.selectedTemplateID) {
        setShowApplyButton(true);
      } else {
        setShowApplyButton(false);
      }
    }
    if (!isAuthorized) {
      navigate("/auth/login");
    }
    if (isAuthorized) {
      setTimeout(() => {
        if (
          (templateUserData?.status === "Draft" ||
            templateUserData?.status === "draft") &&
          templateUserData?.id
        ) {
          navigate(`/edit-template/${templateUserData?.selectedTemplateID}`, {
            state: "abc",
          });
        } else if (
          templateUserData?.status === "Published" ||
          templateUserData?.status === "published"
        ) {
          if (templateUserData?.selectedTemplateID!) {
            setTemplateID(templateUserData?.selectedTemplateID!);
            loadViews(templateUserData?.selectedTemplateID!);
          } else {
            loadViews(-1);
          }
        } else if (
          !templateUserData?.status &&
          !templateUserData?.id &&
          templateUserData?.selectedTemplateID
        ) {
          navigate(`/edit-template/${templateUserData?.selectedTemplateID}`, {
            state: "abc",
          });
        } else {
          if (templateUserData?.selectedTemplateID!) {
            setTemplateID(templateUserData?.selectedTemplateID!);
            loadViews(templateUserData?.selectedTemplateID!);
          } else if (!templateUserData?.selectedTemplateID) {
            loadViews(-1);
          }
        }
      }, 1000);
    } else {
      navigate("/auth/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, navigate, templateUserData?.selectedTemplateID]);

  const formik = useFormik({
    initialValues: isAuthorized
      ? templateUserData
      : templateUserData
      ? templateUserData
      : initTemplateUserData,
    onSubmit: (values: any, { setSubmitting }: any) => {
      setLoading(true);
      setTimeout(() => {
        if (isAuthorized) {
          addTemplateUserData({
            ...values,
            id: templateUserData.id,
            status: "Published",
            logoShape: templateUserData?.logoShape,
            firstName: firstCharCapital(templateUserData?.firstName),
            lastName: firstCharCapital(templateUserData?.lastName),
            selectedTemplateID: templateId
              ? templateId
              : templateUserData?.selectedTemplateID,
            profile_image: templateUserData?.profile_image?.filename_disk,
            company_logo: templateUserData?.company_logo?.filename_disk,
            templateColor: templateUserData?.templateColor,
            templateSecondaryColor: templateUserData?.templateSecondaryColor,
            croppedAreaLogo: templateUserData.croppedAreaLogo,
            croppedAreaProfile: templateUserData.croppedAreaProfile,
          })
            .then((res) => {
              setLoading(false);
              dispatch(
                templateUserDataRedux.actions.setTemplateUserData({
                  ...templateUserData,
                  selectedTemplateID: templateId,
                  status: "Published",
                })
              );
              dispatch(templateUserDataRedux.actions.unsetFromEdit());
              setShowApplyButton(false);
            })
            .catch((e) => {
              setLoading(false);
              setSubmitting(false);
              dispatch(templateUserDataRedux.actions.unsetFromEdit());
            });
        } else {
          dispatch(templateUserDataRedux.actions.setFromEdit(true));
          setTimeout(() => {
            navigate(`/auth/login`, {
              state: {
                mobileNumber: values.mobileNumber,
              },
            });
          }, 300);
        }
      }, 100);
    },
  });

  const handleCircleClickColor = (val: any) => {
    let color = val?.hex ? val?.hex : "#234170";
    root?.style.setProperty("--theme05", color);
    colors?.forEach((element: any, index: number) => {
      if ((element?.color).toLowerCase() === val?.hex) {
        secondaryColor = element?.lightColor;
        root?.style.setProperty("--secondary05", element?.lightColor);
      }
    });
    setShowApplyButton(true);
    dispatch(
      templateUserDataRedux.actions.setTemplateUserData({
        ...templateUserData,
        templateColor: color,
        templateSecondaryColor: secondaryColor!,
      })
    );
    formik.setValues({
      ...formik.values,
      templateColor: color,
      templateSecondaryColor: secondaryColor,
    });
  };

  const handleDefaultCircleClickColor = (val: any) => {
    let color = val?.hex ? val?.hex : "#234170";
    root?.style.setProperty("--theme05", color);
    templateList?.map((template: any) => {
      if (template?.id === templateId) {
        setTemplateColor(template?.templateColor);
        setTemplateLightColor(template?.templateSecondaryColor);
        secondaryColor = template?.templateSecondaryColor;
        root?.style.setProperty(
          "--secondary05",
          template?.templateSecondaryColor
        );
        dispatch(
          templateUserDataRedux.actions.setTemplateUserData({
            ...templateUserData,
            templateColor: template?.templateColor,
            templateSecondaryColor: template?.templateSecondaryColor!,
          })
        );
        setShowApplyButton(true);
        formik.setValues({
          ...formik.values,
          templateColor: template?.templateColor,
          templateSecondaryColor: template?.templateSecondaryColor,
        });
      }
      return "";
    });
  };

  return (
    <>
    <SkeletonTheme baseColor={templateUserData?.templateColor} highlightColor={templateUserData?.templateSecondaryColor}>
      <main className="main">
        <div className="profile-preview-page">
          <div className="container">
            <div className="three-col-view">
              <div className="cell-items d-md-block d-none">
                <h2 className="heading text-center"> Select Card</h2>
                <div className="select-cards scroll-sticky">
                  <div className="App">
                    <section className="slider">
                      <div className="slider__flex">
                        <div className="slider__images desktop">
                          {templateList &&
                            templateList.map((item: Item, index: number) => (
                              <div
                                key={index}
                                className={`slide-item ${
                                  parseInt(item.id) ===
                                  templateUserData?.selectedTemplateID
                                    ? "badge-active"
                                    : ""
                                }`}
                                onClick={() => loadViews(parseInt(item.id))}
                              >
                                <div
                                  className={`slider__image ${
                                    parseInt(item.id) === templateId
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={`${ASSEST_URL}/${item.image?.filename_disk}`}
                                    alt={`/${item.name}`}
                                    width="727"
                                    height="456"
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div className="cell-items color-choose-mobile" ref={navRef}>
                <h2 className="heading text-center">Choose Color</h2>
                <div className="color-section">
                  <div className="color-inner-rounded">
                    <button
                      type="button"
                      className="btn p-0 color-btn bg-transparent"
                      onClick={onToggleClick}
                    >
                      <div className="setting-icon">
                        <img
                          src="/media/images/color-picker.png"
                          alt="colorpicker"
                          width={26}
                          height={26}
                        />
                      </div>
                    </button>
                  </div>

                  <div className="color-choose-wrap">
                    <div className="d-flex mb-3 justify-content-spaceBetween">
                      <div className="d-flex flex-column w-100">
                        <img
                          src="/media/images/color-picker.png"
                          alt="colorpicker"
                          width={30}
                          height={30}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-nav p-0 color-closed "
                        onClick={onToggleClick}
                      >
                        <div className="icon-closed">
                          <img
                            src="/media/images/close.png"
                            alt="close"
                            width={24}
                            height={24}
                          />
                        </div>
                      </button>
                    </div>
                    <div className="d-flex justify-content-between pt-3 mt-3 mb-3 default-color-indicator flex-wrap border-top">
                      <div className="d-flex align-items-center mb-2">
                        <CirclePicker
                          className="w-auto"
                          colors={[`${tempColor}`]}
                          circleSize={20}
                          onChangeComplete={(color: any) =>
                            handleDefaultCircleClickColor(color)
                          }
                        />
                        <span className="lebel"> Default Color</span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <CirclePicker
                          className="w-auto"
                          colors={[`${templateUserData?.templateColor}`]}
                          circleSize={20}
                          onChangeComplete={(color: any) =>
                            handleCircleClickColor(color)
                          }
                        />
                        <span className="lebel"> Current Color</span>
                      </div>
                    </div>
                    {/* Mobile View Pick Color Start */}
                    <div>
                      <div className="select-cards scroll-sticky">
                        <div className="App">
                          <section className="slider">
                            <div className="slider__flex">
                              <div className="slider__images mobile-view">
                                <div className="color-plates">
                                  <CirclePicker
                                    className="w-100 justify-content-between"
                                    colors={colors?.map(
                                      (col: any) => col?.color
                                    )}
                                    circleSize={20}
                                    onChangeComplete={(color: any) =>
                                      handleCircleClickColor(color)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="color-plates position-relative">
                    <CirclePicker
                      className="w-100 justify-content-start"
                      colors={colors?.map((col: any) => col?.color)}
                      circleSize={20}
                      onChangeComplete={(color: any) =>
                        handleCircleClickColor(color)
                      }
                    />
                    <div className="d-flex justify-content-between pt-4 mt-4 default-color-indicator flex-wrap border-top">
                      <div className="d-flex align-items-center mb-2">
                        <CirclePicker
                          className="w-auto"
                          colors={[`${tempColor}`]}
                          circleSize={20}
                          onChangeComplete={(color: any) =>
                            handleDefaultCircleClickColor(color)
                          }
                        />
                        <span> Default Color</span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <CirclePicker
                          className="w-auto"
                          colors={[`${templateUserData?.templateColor}`]}
                          circleSize={20}
                          onChangeComplete={(color: any) =>
                            handleCircleClickColor(color)
                          }
                        />
                        <span> Current Color</span>
                      </div>
                    </div>
                  </div>
                  {showApplyButton ? (
                    <Buttons
                      btnText={loading ? "Please wait..." : "Apply & Publish"}
                      buttonName={"button"}
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                      className="w-100 mt-0 mt-md-3"
                      disabled={loading ? true : false}
                    ></Buttons>
                  ) : (
                    <div>
                      {showShareButton ? (
                        <div className="share-btn">
                          <Buttons
                            btnText={"Share"}
                            buttonName={"button"}
                            type="submit"
                            onClick={() => setModalShow(true)}
                            className="w-100 mt-0 mt-md-3"
                          ></Buttons>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="cell-items preview-final">
                <h2 className="heading text-center mt-md-0 mt-4">Preview</h2>
                  {views}
              </div>
            </div>

            <div className="side-temp-preview">
              <button
                type="button"
                className="btn p-0 color-btn bg-transparent preview-btns"
                onClick={onTemplatePrvToggle}
              >
                <div className="preview-icon">
                  <img
                    src="/media/images/card03.svg"
                    alt="card-icon"
                    width={30}
                    height={28}
                  />
                </div>
              </button>
              <div className="temp-list" ref={prevToggleRef}>
                <div className="inner-view">
                  <div className="d-flex justify-content-between mb-4 align-items-center">
                    <h5 className="mb-0 fs-6">Templates</h5>
                    <button
                      type="button"
                      className="btn btn-nav p-0 color-closed"
                      onClick={onTemplatePrvToggle}
                    >
                      <div className="icon-close">
                        <img
                          src="/media/images/close.png"
                          alt="close"
                          width={18}
                          height={18}
                        />
                      </div>
                    </button>
                  </div>
                  <div>
                    <div className="select-cards scroll-sticky">
                      <div className="App">
                        <section className="slider">
                          <div className="slider__flex">
                            <div className="slider__images mobile-view">
                              {templateList &&
                                templateList.map(
                                  (item: Item, index: number) => (
                                    <div
                                      key={index}
                                      className={`slide-item ${
                                        parseInt(item.id) ===
                                        templateUserData?.selectedTemplateID
                                          ? "badge-active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        loadViews(parseInt(item.id))
                                      }
                                    >
                                      <div
                                        className={`slider__image ${
                                          parseInt(item.id) === templateId
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <img
                                          src={`${ASSEST_URL}/${item.image?.filename_disk}`}
                                          alt={`/${item.name}`}
                                          width="727"
                                          height="456"
                                        />
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ShareModal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </main>
      </SkeletonTheme>
    </>
  );
};

export default ProfilePreview;
