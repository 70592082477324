
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

function Videosection() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000)
    return () => clearTimeout(timer);
  }, [])
  return (
    <>
      <div className="video-section" id="video">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              {loading ? (
                <Skeleton height={70} width={1000} style={{ marginTop: "110px" ,marginBottom:"50px"}} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
                <h2 className="heading-medium mb-4 mb-lg-5">
                  See, how simple it is to create & share
                </h2>
              }
              {loading ? (
                <Skeleton height={500} width={1200} style={{ marginTop: "110px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <div className="video-wrap">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/sOJFYchBPSc"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Videosection;
