import "../../templates/scss/temp07.scss";
import SVG from "react-inlinesvg";
import user01 from "../images/user01.png";
import call from "../images/icons/call.svg";
import map from "../images/icons/map.svg";
import envelop from "../images/icons/envelop.svg";
import location from "../images/icons/location.svg";
import whatsapp from "../images/icons/whatsapp.svg";
import instagram from "../images/icons/instagram.svg";
import youtube from "../images/icons/youtube.svg";
import twitter from "../images/icons/twitter.svg";
import facebook from "../images/icons/facebook.svg";
import shareNew from "../images/icons/shareNew.svg";
import reviewNew from "../images/icons/reviewNew.svg";
import loading from "../images/misc/loading.gif";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../setup";
import { parseUrl, socialSharing } from "../../app/helper/helper";
import { useEffect, useState } from "react";
import { TemplateUserDataRedux } from "../../app/modules/template/redux/ReduxDispatchExport";
import {
  ASSEST_URL,
  PUBLIC_URL,
  RAZORPAY_ENABLED,
} from "../../app/helper/EnvVar";
import { ReviewModal } from "../../app/pages/templates/components/Templates/common/ReviewModal";
import { SocialSharingModal } from "../../app/helper/SocialSharingModal";
import { Avatar } from "@mui/material";
import pocketlogo from "../images/pocketsmall.svg";
import contactadd from "../images/icons/contactadd.svg";
import { QRCodeSVG } from "qrcode.react";
import { downloadVCFFile } from "../../downloadVCF";
import Skeleton from "react-loading-skeleton";
import PrintableModal from "../../components/PrintModal";
import TourPremiumFeatures from "../../components/tour-premium-features.tsx/TourPremiumFeatures";

type Props = {
  profilePreview: any;
  companyLogoPreview: any;
  loader?: boolean;
  logoChangeLoading?: boolean;
  premium?: boolean;
  editMode?: boolean;
  skeletonLoading?: boolean;
  reacTour?: boolean;
};

const Template07 = ({
  profilePreview,
  companyLogoPreview,
  loader,
  logoChangeLoading,
  premium,
  editMode,
  skeletonLoading,
  reacTour
}: Props) => {
  const templateUserData = useSelector(
    (state: RootState) => state.templateUserData.templateUserData!
  );
  const auth = useSelector((state: RootState) => state.auth.isAuthorized!);
  const dispatch = useDispatch();
  const [reviewModal, setReviewModal] = useState<boolean>(false);
  const [showFallback, setShowFallback] = useState<boolean>(false);
  const [sLoading, setsLoading] = useState(skeletonLoading)
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isTourOpen, setIsTourOpen] = useState<any>();
  const [currentStep, setCurrentStep] = useState<any>();

  useEffect(() => {
    if (RAZORPAY_ENABLED === "true") {
      if (templateUserData?.premium_plan === true || premium === true) {
        if(currentStep === undefined && isTourOpen !== false){
          if(reacTour !== false){
            setTimeout(() => {
              setIsTourOpen(true);
            }, 100);
          }
        }
      }
    }
  });
  
  useEffect(() => {
    if(sLoading === true){
      setTimeout(() => {
        setsLoading(false);
      }, 500);
    }
  }, []);

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      TemplateUserDataRedux.actions.setTemplateUserData({
        ...templateUserData!,
        templateColor: templateUserData?.templateColor || "#234170",
      })
    );
    setColor(templateUserData?.templateColor || "#234170");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateUserData?.selectedTemplateID, dispatch]);

  function setColor(newColor: any) {
    let color = newColor ? newColor : "#234170";
    document.documentElement.style.setProperty("--theme05", color);
    document.documentElement.style.setProperty(
      "--secondary05",
      templateUserData?.templateSecondaryColor || "#f4f7fc"
    );
  }

  const showReviewModal = () => {
    setReviewModal(!reviewModal);
  };

  const handleClose = () => {
    setShowFallback(!showFallback);
  };

  const shareButton = () => {
    socialSharing(templateUserData, () => {
      setShowFallback(true);
    });
  };

  const HandleClick = () => {
    window.location.href = "auth/login";
  };

  const handleShow = () => setShowModal(!showModal);
  const handleCloseModal = () => setShowModal(!showModal);
  const handlePrint = () => {
    const contentElement = document.getElementById("printContent");

    if (contentElement) {
      const content = contentElement.innerHTML;
      const newWindow = window.open("", "_blank");

      if (newWindow) {
        newWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
            </head>
            <body>
              ${content}
            </body>
          </html>
        `);
        newWindow.document.close();
        newWindow.print();
      } else {
        console.error(
          "Unable to open print window. Please check your browser settings."
        );
      }
    } else {
      console.error("Element with ID 'printContent' not found.");
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  
  const buttonStyle: React.CSSProperties = {
    color: `${templateUserData?.templateColor}`,
    border: `1px solid ${templateUserData?.templateColor}`,
    borderRadius: "15px",
    marginLeft: "7px",
    padding: "0px 10px",
    whiteSpace: "nowrap",
    backgroundColor: "transparent",
    transition: "background-color 0.3s",
  };

  const hoverStyle: React.CSSProperties = {
    backgroundColor: `${templateUserData?.templateColor}`,
    color: "white",
  };

  const buttonStyles = isHovered ? { ...buttonStyle, ...hoverStyle } : buttonStyle;
  return (
    <>
      <div className="theme05 template-main">
        <TourPremiumFeatures
          isTourOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
          setCurrentStep={setCurrentStep}
        />
        <div className="temp-inner">
          <div className="temp-header p-0">
            {RAZORPAY_ENABLED === "true" ? (
              (templateUserData?.premium_plan === true || premium === true) &&
              (templateUserData?.isCompanyLogo ? (
                <div
                  className={`${
                    templateUserData?.logoShape === "circle"
                      ? "card-logo-rectangle"
                      : "card-logo-square"
                  } logo-bg-shape shape-left`}
                >
                  {sLoading ? (
                    <Skeleton
                      height={
                        templateUserData?.logoShape === "circle" ? 30 : 40
                      }
                      width={
                        templateUserData?.logoShape === "circle" ? 120 : 40
                      }
                    />
                  ) : (
                    <div className="logo-set">
                      {templateUserData?.company_logo && loader ? (
                        <Avatar
                          src={loading}
                          alt="loding"
                          variant="square"
                          style={{ width: 60, height: 60 }}
                          className="ps-3 pe-3 pb-4 pt-4"
                        />
                      ) : templateUserData?.logoShape === "circle" ? (
                        logoChangeLoading ? (
                          <Avatar
                            src={loading}
                            alt="loding"
                            variant="square"
                            style={{ width: 60, height: 60 }}
                            className="ps-3 pe-3 pb-4 pt-4"
                          />
                        ) : (
                          <img
                            src={
                              templateUserData?.company_logo
                                ? typeof templateUserData?.company_logo !=
                                  "string"
                                  ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}` ||
                                    (templateUserData?.company_logo &&
                                      URL.createObjectURL(
                                        templateUserData?.company_logo
                                      ))
                                  : templateUserData?.company_logo
                                : templateUserData?.company_logo &&
                                  URL.createObjectURL(
                                    templateUserData?.company_logo
                                  )
                                ? templateUserData?.company_logo &&
                                  URL.createObjectURL(
                                    templateUserData?.company_logo
                                  )
                                : companyLogoPreview
                            }
                            className="logo-img p-3"
                            alt="logo"
                          />
                        )
                      ) : logoChangeLoading ? (
                        <Avatar
                          src={loading}
                          alt="loding"
                          variant="square"
                          style={{ width: 60, height: 60 }}
                          className="ps-3 pe-3 pb-4 pt-4"
                        />
                      ) : (
                        <Avatar
                          src={
                            templateUserData?.company_logo
                              ? typeof templateUserData?.company_logo !=
                                "string"
                                ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}` ||
                                  (templateUserData?.company_logo &&
                                    URL.createObjectURL(
                                      templateUserData?.company_logo
                                    ))
                                : templateUserData?.company_logo
                              : templateUserData?.company_logo &&
                                URL.createObjectURL(
                                  templateUserData?.company_logo
                                )
                              ? templateUserData?.company_logo &&
                                URL.createObjectURL(
                                  templateUserData?.company_logo
                                )
                              : companyLogoPreview
                          }
                          variant="square"
                          alt="logo"
                          className="logo-img"
                        />
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ""
              ))
            ) : templateUserData?.isCompanyLogo ? (
              <div
                className={`${
                  templateUserData?.logoShape === "circle"
                    ? "card-logo-rectangle"
                    : "card-logo-square"
                } logo-bg-shape shape-left`}
              >
                {sLoading ? (
                  <Skeleton
                    height={templateUserData?.logoShape === "circle" ? 30 : 40}
                    width={templateUserData?.logoShape === "circle" ? 120 : 40}
                  />
                ) : (
                  <div className="logo-set">
                    {templateUserData?.company_logo && loader ? (
                      <Avatar
                        src={loading}
                        alt="loding"
                        variant="square"
                        style={{ width: 60, height: 60 }}
                        className="ps-3 pe-3 pb-4 pt-4"
                      />
                    ) : templateUserData?.logoShape === "circle" ? (
                      logoChangeLoading ? (
                        <Avatar
                          src={loading}
                          alt="loding"
                          variant="square"
                          style={{ width: 60, height: 60 }}
                          className="ps-3 pe-3 pb-4 pt-4"
                        />
                      ) : (
                        <img
                          src={
                            templateUserData?.company_logo
                              ? typeof templateUserData?.company_logo !=
                                "string"
                                ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}` ||
                                  (templateUserData?.company_logo &&
                                    URL.createObjectURL(
                                      templateUserData?.company_logo
                                    ))
                                : templateUserData?.company_logo
                              : templateUserData?.company_logo &&
                                URL.createObjectURL(
                                  templateUserData?.company_logo
                                )
                              ? templateUserData?.company_logo &&
                                URL.createObjectURL(
                                  templateUserData?.company_logo
                                )
                              : companyLogoPreview
                          }
                          className="logo-img p-3"
                          alt="logo"
                        />
                      )
                    ) : logoChangeLoading ? (
                      <Avatar
                        src={loading}
                        alt="loding"
                        variant="square"
                        style={{ width: 60, height: 60 }}
                        className="ps-3 pe-3 pb-4 pt-4"
                      />
                    ) : (
                      <Avatar
                        src={
                          templateUserData?.company_logo
                            ? typeof templateUserData?.company_logo != "string"
                              ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}` ||
                                (templateUserData?.company_logo &&
                                  URL.createObjectURL(
                                    templateUserData?.company_logo
                                  ))
                              : templateUserData?.company_logo
                            : templateUserData?.company_logo &&
                              URL.createObjectURL(
                                templateUserData?.company_logo
                              )
                            ? templateUserData?.company_logo &&
                              URL.createObjectURL(
                                templateUserData?.company_logo
                              )
                            : companyLogoPreview
                        }
                        variant="square"
                        alt="logo"
                        className="logo-img"
                      />
                    )}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            <div className="user-avtar-cols py-5 ps-3 pe-4 ale-bg">
              <div className="col name-cols">
                {sLoading ? (
                  <Skeleton width={163} height={34} />
                ) : (
                  <div className="user-name">
                    {templateUserData?.firstName || "John"}{" "}
                    {templateUserData?.lastName || "Doe"}
                  </div>
                )}
                {RAZORPAY_ENABLED === "true" ? (
                  (templateUserData?.premium_plan === true ||
                    premium === true) &&
                  (sLoading ? (
                    <Skeleton width={43} height={24} />
                  ) : (
                    <div className="user-post mb-2">
                      {templateUserData?.designation || `Co-founder `}
                    </div>
                  ))
                ) : sLoading ? (
                  <Skeleton width={43} height={24} />
                ) : (
                  <div className="user-post mb-2">
                    {templateUserData?.designation || `Co-founder `}
                  </div>
                )}
                {RAZORPAY_ENABLED === "true" ? (
                  (templateUserData?.premium_plan === true ||
                    premium === true) &&
                  (sLoading ? (
                    <Skeleton width={60} height={24} />
                  ) : (
                    <div className="company-name">
                      {templateUserData?.isCompanyName
                        ? templateUserData?.companyName || "Infinity Aotumation"
                        : ""}
                    </div>
                  ))
                ) : sLoading ? (
                  <Skeleton width={100} height={24} />
                ) : (
                  <div className="company-name">
                    {templateUserData?.isCompanyName
                      ? templateUserData?.companyName || "Infinity Aotumation"
                      : ""}
                  </div>
                )}
              </div>
              {sLoading ? (
                <div className="circle-skeleton"></div>
              ) : (
                <div className="cols">
                  <div className="user-img rounded">
                    {templateUserData?.profile_image ? (
                      <Avatar
                        src={
                          templateUserData?.profile_image
                            ? typeof templateUserData?.profile_image != "string"
                              ? `${ASSEST_URL}/${templateUserData?.profile_image?.filename_disk}` ||
                                (templateUserData?.profile_image &&
                                  URL.createObjectURL(
                                    templateUserData?.profile_image
                                  ))
                              : templateUserData?.profile_image
                            : profilePreview
                        }
                        variant="circular"
                        alt="user-img"
                        style={{ width: "130px", height: "130px" }}
                      />
                    ) : (
                      <Avatar
                        src={user01}
                        variant="circular"
                        alt="user-img"
                        style={{ width: "130px", height: "130px" }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="temp-lower pt-4">
            {sLoading ? (
              <Skeleton width={290} height={20} />
            ) : (
              <p
                className="desc"
                dangerouslySetInnerHTML={{
                  __html:
                    templateUserData?.description?.replace(/\n/g, "</br>") ||
                    `Award Winning web-developer with 7 years of experience in HTML
              ,CSS, LAMP, My main interests are object-oriented and
              user-centered design.`,
                }}
              />
            )}

            <div className="services">
              {templateUserData?.service1?.name ||
              templateUserData?.service2?.name ||
              templateUserData?.service3?.name ||
              templateUserData?.service4?.name ? (
                sLoading ? (
                  <Skeleton width={50} height={10} />
                ) : (
                  <div className="service-title mb-3">Services</div>
                )
              ) : (
                ""
              )}

              <ul className="list-unstyled d-flex service-list">
                {templateUserData?.service1?.name ? (
                  <li className="item" key={templateUserData?.service1?.id}>
                    <div className="ser-link">
                      {sLoading ? (
                        <Skeleton
                          width={40}
                          height={40}
                          style={{ borderRadius: "25px", marginTop: "20px" }}
                        />
                      ) : (
                        <div
                          className="icon d-flex align-items-center justify-content-center"
                          style={{
                            background: `${
                              templateUserData?.templateColor || "#234170"
                            }`,
                            padding: "7px",
                            borderRadius: "30px",
                            height: "40px",
                            width: "40px",
                          }}
                        >
                          <SVG
                            src={`${ASSEST_URL}/${templateUserData?.service1?.svg?.filename_disk}`}
                            fill="white"
                            style={{ height: "23.434", width: "26.071" }}
                          />
                        </div>
                      )}
                      {sLoading ? (
                        <Skeleton width={55} height={15} />
                      ) : (
                        <span className="service-name">
                          {templateUserData?.service1?.name}
                        </span>
                      )}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {templateUserData?.service2?.name ? (
                  <li className="item" key={templateUserData?.service2?.id}>
                    <div className="ser-link">
                      {sLoading ? (
                        <Skeleton
                          width={40}
                          height={40}
                          style={{ borderRadius: "25px", marginTop: "20px" }}
                        />
                      ) : (
                        <div
                          className="icon d-flex align-items-center justify-content-center"
                          style={{
                            background: `${
                              templateUserData?.templateColor || "#234170"
                            }`,
                            padding: "7px",
                            borderRadius: "30px",
                            height: "40px",
                            width: "40px",
                          }}
                        >
                          <SVG
                            src={`${ASSEST_URL}/${templateUserData?.service2?.svg?.filename_disk}`}
                            fill="white"
                            style={{ height: "23.434", width: "26.071" }}
                          />
                        </div>
                      )}
                      {sLoading ? (
                        <Skeleton width={55} height={15} />
                      ) : (
                        <span className="service-name">
                          {templateUserData?.service2?.name}
                        </span>
                      )}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {templateUserData?.service3?.name ? (
                  <li className="item" key={templateUserData?.service3?.id}>
                    <div className="ser-link">
                      {sLoading ? (
                        <Skeleton
                          width={40}
                          height={40}
                          style={{ borderRadius: "25px", marginTop: "20px" }}
                        />
                      ) : (
                        <div
                          className="icon d-flex align-items-center justify-content-center"
                          style={{
                            background: `${
                              templateUserData?.templateColor || "#234170"
                            }`,
                            padding: "7px",
                            borderRadius: "30px",
                            height: "40px",
                            width: "40px",
                          }}
                        >
                          <SVG
                            src={`${ASSEST_URL}/${templateUserData?.service3?.svg?.filename_disk}`}
                            fill="white"
                            style={{ height: "23.434", width: "26.071" }}
                          />
                        </div>
                      )}
                      {sLoading ? (
                        <Skeleton width={55} height={15} />
                      ) : (
                        <span className="service-name">
                          {templateUserData?.service3?.name}
                        </span>
                      )}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {templateUserData?.service4?.name ? (
                  <li className="item" key={templateUserData?.service4?.id}>
                    <div className="ser-link">
                      {sLoading ? (
                        <Skeleton
                          width={40}
                          height={40}
                          style={{ borderRadius: "25px", marginTop: "20px" }}
                        />
                      ) : (
                        <div
                          className="icon d-flex align-items-center justify-content-center"
                          style={{
                            background: `${
                              templateUserData?.templateColor || "#234170"
                            }`,
                            padding: "7px",
                            borderRadius: "30px",
                            height: "40px",
                            width: "40px",
                          }}
                        >
                          <SVG
                            src={`${ASSEST_URL}/${templateUserData?.service4?.svg?.filename_disk}`}
                            fill="white"
                            style={{ height: "23.434", width: "26.071" }}
                          />
                        </div>
                      )}
                      {sLoading ? (
                        <Skeleton width={55} height={15} />
                      ) : (
                        <span className="service-name">
                          {templateUserData?.service4?.name}
                        </span>
                      )}
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>

            <div className="d-flex position-relative justify-content-between">
              <ul className="contact-detail mb-2">
                <li className="item">
                  <a
                    href={`tel:${
                      templateUserData?.mobileNumber
                        ? templateUserData?.mobileNumber
                        : "#"
                    }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                  >
                    {sLoading ? (
                      <Skeleton
                        width={24}
                        height={24}
                        style={{ marginTop: "20px" }}
                      />
                    ) : (
                      <span className="icon">
                        <SVG
                          src={call}
                          fill={templateUserData?.templateColor || "#234170"}
                        />
                      </span>
                    )}
                    {sLoading ? (
                      <Skeleton
                        width={107}
                        height={21}
                        style={{ marginLeft: "10px", marginTop: "20px" }}
                      />
                    ) : (
                      <span className="link-text">
                        {templateUserData?.mobileNumber
                          ? templateUserData?.mobileNumber
                          : `+91 9999999999`}
                      </span>
                    )}
                  </a>
                </li>
                {templateUserData?.isWebsite ? (
                  <li className="item">
                    <a
                      href={`${
                        templateUserData?.websiteLink
                          ? parseUrl(templateUserData?.websiteLink)
                          : ""
                      }`}
                      target={"_blank"}
                      className="contact-link"
                      rel="noreferrer"
                    >
                      {sLoading ? (
                        <Skeleton width={24} height={24} />
                      ) : (
                        <span className="icon">
                          <SVG
                            src={map}
                            fill={templateUserData?.templateColor || "#234170"}
                          />
                        </span>
                      )}
                      {sLoading ? (
                        <Skeleton
                          width={107}
                          height={21}
                          style={{ marginLeft: "10px" }}
                        />
                      ) : (
                        <span className="link-text">
                          {templateUserData?.websiteLink
                            ? templateUserData?.websiteLink.replace(
                                /(^\w+:|^)\/\//,
                                ""
                              )
                            : `www.mobilevisit.com`}
                        </span>
                      )}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li className="item">
                  <a
                    href={`mailto:${
                      templateUserData?.email ? templateUserData?.email : ""
                    }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                  >
                    {sLoading ? (
                      <Skeleton width={24} height={24} />
                    ) : (
                      <span className="icon">
                        <SVG
                          src={envelop}
                          fill={templateUserData?.templateColor || "#234170"}
                        />
                      </span>
                    )}
                    {sLoading ? (
                      <Skeleton
                        width={107}
                        height={21}
                        style={{ marginLeft: "10px" }}
                      />
                    ) : (
                      <span className="link-text">
                        {templateUserData?.email
                          ? templateUserData?.email
                          : `youremailid@somedomain.com`}
                      </span>
                    )}
                  </a>
                </li>
                <li className="item">
                  <a
                    href={`http://maps.google.com/?q=${
                      templateUserData?.address
                        ? templateUserData?.address
                        : `425, Shiven Square, Pal,Adajan, Surat, Gujarat, India - 395009`
                    }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                  >
                    {sLoading ? (
                      <Skeleton width={24} height={24} />
                    ) : (
                      <span className="icon">
                        <SVG
                          src={location}
                          fill={templateUserData?.templateColor || "#234170"}
                        />
                      </span>
                    )}
                    {sLoading ? (
                      <Skeleton
                        width={107}
                        height={21}
                        style={{ marginLeft: "10px" }}
                      />
                    ) : (
                      <span className="link-text">
                        {templateUserData?.address ||
                          `425, Shiven Square, Pal,Adajan, Surat, Gujarat, India -
                    395009`}
                      </span>
                    )}
                  </a>
                </li>
              </ul>

              <div className="d-flex flex-column text-end">
                {editMode === true &&
                  (sLoading ? (
                    <Skeleton
                      width={70}
                      height={70}
                      style={{ marginBottom: "5px", marginTop: "20px" }}
                    />
                  ) : (
                    <>
                      <span
                        className="qr-icon bordered mt-1 mb-2"
                        onClick={handleShow}
                      >
                        <QRCodeSVG
                          value={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}
                          width="70"
                          height="70"
                          size={128}
                          bgColor={"#ffffff"}
                          fgColor={"#000000"}
                          level={"L"}
                          includeMargin={false}
                          imageSettings={{
                            src: "",
                            x: undefined,
                            y: undefined,
                            height: 2,
                            width: 2,
                            excavate: true,
                          }}
                        />
                      </span>
                      <PrintableModal
                        showModal={showModal}
                        handleCloseModal={handleCloseModal}
                        templateUserData={templateUserData}
                        handlePrint={handlePrint}
                      />
                    </>
                  ))}

                {editMode === true &&
                  (sLoading ? (
                    <Skeleton width={70} height={31} />
                  ) : (
                    <div className="d-flex justify-content-center text-center">
                      <button
                        type="button"
                        className="addcontact-btn"
                        aria-label="addcontact-btn"
                        onClick={() => downloadVCFFile(templateUserData)}
                      >
                        <SVG src={contactadd} width="38" height="20" />
                      </button>
                    </div>
                  ))}
              </div>
            </div>

            <div className="social-media-wrap">
              <ul className="social-media">
                {templateUserData?.isWhatsapp ? (
                  sLoading ? (
                    <div className="circle-skeleton-whatsap"></div>
                  ) : (
                    <li className="item">
                      <a
                        href={`https://wa.me/${templateUserData?.whatsAppLink}`}
                        target={"_blank"}
                        className="social-link"
                        rel="noreferrer"
                      >
                        <SVG
                          src={whatsapp}
                          stroke={templateUserData?.templateColor || "#234170"}
                          fill={templateUserData?.templateColor || "#234170"}
                        />
                      </a>
                    </li>
                  )
                ) : (
                  ""
                )}
                {templateUserData?.isYouTube ? (
                  sLoading ? (
                    <div className="circle-skeleton-whatsap"></div>
                  ) : (
                    <li className="item">
                      <a
                        href={
                          templateUserData?.youTubeLink
                            ? parseUrl(templateUserData?.youTubeLink)
                            : ""
                        }
                        target={"_blank"}
                        className="social-link"
                        rel="noreferrer"
                      >
                        <SVG
                          src={youtube}
                          stroke={templateUserData?.templateColor || "#234170"}
                          fill={templateUserData?.templateColor || "#234170"}
                        />
                      </a>
                    </li>
                  )
                ) : (
                  ""
                )}
                {templateUserData?.isInstagram ? (
                  sLoading ? (
                    <div className="circle-skeleton-whatsap"></div>
                  ) : (
                    <li className="item">
                      <a
                        href={
                          templateUserData?.instagramLink
                            ? parseUrl(templateUserData?.instagramLink)
                            : ""
                        }
                        target={"_blank"}
                        className="social-link"
                        rel="noreferrer"
                      >
                        <SVG
                          src={instagram}
                          stroke={templateUserData?.templateColor || "#234170"}
                          fill={templateUserData?.templateColor || "#234170"}
                        />
                      </a>
                    </li>
                  )
                ) : (
                  ""
                )}
                {templateUserData?.isTwitter ? (
                  sLoading ? (
                    <div className="circle-skeleton-whatsap"></div>
                  ) : (
                    <li className="item">
                      <a
                        href={
                          templateUserData?.twitterLink
                            ? parseUrl(templateUserData?.twitterLink)
                            : ""
                        }
                        target={"_blank"}
                        className="social-link"
                        rel="noreferrer"
                      >
                        <SVG
                          src={twitter}
                          stroke={templateUserData?.templateColor || "#234170"}
                          fill={templateUserData?.templateColor || "#234170"}
                        />
                      </a>
                    </li>
                  )
                ) : (
                  ""
                )}
                {templateUserData?.isFacebook ? (
                  sLoading ? (
                    <div className="circle-skeleton-whatsap"></div>
                  ) : (
                    <li className="item">
                      <a
                        href={
                          templateUserData?.facebookLink
                            ? parseUrl(templateUserData?.facebookLink)
                            : ""
                        }
                        target={"_blank"}
                        className="social-link"
                        rel="noreferrer"
                      >
                        <SVG
                          src={facebook}
                          stroke={templateUserData?.templateColor || "#234170"}
                          fill={templateUserData?.templateColor || "#234170"}
                        />
                      </a>
                    </li>
                  )
                ) : (
                  ""
                )}
              </ul>

              <ul className="list-unstyled m-0 p-0 share-icon-wrap">
                {editMode === true &&
                  (sLoading ? (
                    <div className="circle-skeleton-whatsap"></div>
                  ) : (
                    <li className="item show-on-share">
                      <button
                        type="button"
                        className="social-link share-button"
                        aria-label="share button"
                        style={{
                          borderWidth: "0px",
                          background: "transparent",
                        }}
                        onClick={shareButton}
                      >
                        <SVG
                          src={shareNew}
                          style={{ width: "30px", height: "30px" }}
                          stroke={templateUserData?.templateColor || "#a29d06"}
                        />
                      </button>
                    </li>
                  ))}

                {editMode === true &&
                  (sLoading ? (
                    <div className="circle-skeleton-whatsap"></div>
                  ) : (
                    <li className="item show-on-share">
                      <button
                        type="button"
                        aria-label="review button"
                        onClick={() => {
                          !auth && showReviewModal();
                        }}
                        style={{
                          borderWidth: "0px",
                          background: "transparent",
                        }}
                        className="social-link review-button"
                      >
                        <SVG
                          src={reviewNew}
                          style={{ width: "30px", height: "30px" }}
                          stroke={templateUserData?.templateColor || "#a29d06"}
                        />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="d-flex align-items-center powered-block ps-2 justify-content-between">
              {editMode === true &&
                (sLoading ? (
                  <Skeleton width={180} height={22} />
                ) : (
                  <div>
                    Powerd by :
                    <a
                      href="https://pocketsite.me"
                      target="_blank"
                      title="Pocketsite"
                      rel="noreferrer"
                    >
                      <span className="ps-2 pe-1">
                        <SVG
                          src={pocketlogo}
                          className="logosmall"
                          width={18}
                          height={18}
                        />
                      </span>
                      Pocketsite.me
                    </a>
                  </div>
                ))}

                  
              {editMode === true && (sLoading ? (
                  <Skeleton
                    width={93}
                    height={28}
                    style={{ borderRadius: "20px" }}
                  />
                ) : (
                  <button
                    style={buttonStyles}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={HandleClick}
                  >Create yours</button>
                ))}
                

            </div>
          </div>
        </div>
      </div>
      {reviewModal && (
        <ReviewModal show={reviewModal} handleClose={showReviewModal} />
      )}
      {showFallback && (
        <SocialSharingModal
          show={showFallback}
          handleClose={handleClose}
        ></SocialSharingModal>
      )}
    </>
  );
};

export default Template07;
