import { axiosClient } from "../../../setup/axios/SetupAxios";
import { PLANS, RAZORPAY_ORDERS, RAZORPAY_SUCCESS } from "../../helper/APIURL";

export async function ordersAPI(data: any) {
  return await axiosClient.post(`${RAZORPAY_ORDERS}`, { amount: data });
}

export async function paymentSuccessAPI(data: any) {
  return await axiosClient.post(`${RAZORPAY_SUCCESS}`, data);
}

export async function getPlans() {
  return await axiosClient.get(`${PLANS}`);
}
