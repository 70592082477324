import Buttons from "../Buttons";
import Form from "react-bootstrap/Form";
import InputField from "../edit-template/InputField";
import { useEffect, useState } from "react";
import { addSubscribers } from "../../app/modules/template/redux/TemplateUserDataCRUD";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { InitSubscribeForm } from "../../app/pages/templates/components/Templates/common/subscribeHelper/InitSubscribeFormModal";
import { SubscribeFormValidate } from "../../app/pages/templates/components/Templates/common/subscribeHelper/SubscribeFormValidate";

function StayConnected() {
  const [loading, setLoading] = useState(false);
  const [sloading, setsLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setsLoading(false);
    }, 2000)
    return () => clearTimeout(timer);
  }, [])

  const formik = useFormik({
    initialValues: InitSubscribeForm,
    validationSchema: SubscribeFormValidate,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      if(values){
        addSubscribers({
          ...values, status: "Published"
        })
          .then((res) => {
            setSubmitting(false);
            toast.success("Success...! Thank you for subscribing.");
            setLoading(false);
            resetForm();            
          })
          .catch((e) => {
            setSubmitting(false);
            console.log("Error...! Something went wrong");
            setLoading(false);
          });
      }
    }
  });
 
  return (
    <>
      <div className="stay-connected-section" id="contacts">
        <div className="container">
          <div className="text-center">
          {sloading ? (
                <Skeleton height={64} width={500} style={{ marginTop: "30px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
            <h2 className="heading-medium">Stay connected with us</h2>
          }
          {sloading ? (
                <Skeleton height={32} width={600} style={{ marginTop: "16px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
            <p className="mb-40">
              Ignite your success: Subscribe to our newsletter and stay ahead of the game!
            </p>
}
          </div>
          <Form className="mx-auto" onSubmit={formik.handleSubmit}>
            <div className="d-flex justify-content-center stay-connected-form">
            {sloading ? (
                <Skeleton height={48} width={548} style={{ marginTop: "40px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <div className="floating-group mb-sm-0 mb-4 ">
                <InputField
                  type="email"
                  className="mb-0 w-100"
                  placeholder=" "
                  name="email"
                  autoFocus={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onFocus={formik.handleBlur}
                  value={formik?.values?.email}
                />
                <label className="float-label">Your email here</label>
              </div>
}

{sloading ? (
                <Skeleton height={48} width={150} style={{ marginTop: "40px",marginLeft:"20px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
              ) :
              <Buttons
                btnText={loading ? "Please Wait" : "Subscribe"}
                buttonName={"button"}
                className="ms-sm-3 px-4"
                type={"submit"}
                disabled={loading ? true : false}
              ></Buttons>
}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default StayConnected;
