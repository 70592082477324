import React, { lazy, useEffect, useState } from "react";
import { RootState } from "../setup";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { addTemplateUserData } from "../app/modules/template/redux/TemplateUserDataCRUD";
import * as templateUserDataRedux from "../app/modules/template/redux/TemplateUserDataRedux";
import MobileViewPlans from "../components/MobileViewPlans";
import DesktopViewPlans from "../components/DesktopViewPlans";
import { getPlans } from "../app/pages/Payment/payment_api";
import { firstCharCapital } from "../app/helper/helper";

type Props = {
  setPaymentStatus: (value: any) => void;
  setLoading: (value: any) => void;
  paymentStatus: boolean;
  templateId: number;
  uploadProfileImageFile: string;
  uploadCompanyLogoFile: string;
};

const importView = (tempId: number) =>
  lazy(() =>
    import(`../templates/Template0${tempId}`).catch(
      () => import(`../app/pages/NotFound/NotFoundComponent`)
    )
  );

function Plans({
  setPaymentStatus,
  setLoading,
  templateId,
  uploadCompanyLogoFile,
  uploadProfileImageFile,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [plans, setPlans] = useState();
  const templateUserData = useSelector(
    (state: RootState) => state.templateUserData.templateUserData!
  );
  const [views, setViews] = useState<any>();
  const [premiumViews, setPremiumViews] = useState<any>();
  const [width, setWidth] = useState(window.innerWidth);
  const [checked, setChecked] = useState<boolean | undefined>(
    templateUserData?.premium_plan
  );
  const [planName, setPlanName] = useState<string>();

  useEffect(() => {
    getPlans()
      .then((res: any) => setPlans(res?.data?.data))
      .catch((error: any) => console.error(error, "error"));
  },[]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    loadViews(templateUserData?.selectedTemplateID);
  }, []);

  useEffect(() => {
    loadViews(templateUserData?.selectedTemplateID);
  }, []);

  useEffect(() => {
    loadPremiumViews(templateUserData?.selectedTemplateID);
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  async function loadViews(tempId: number) {
    const componentPromises = async (tempId: number) => {
      const View = await importView(tempId);
      const naid: string = nanoid(10);
      return <View key={naid} editMode={true} premium={false} />;
    };
    componentPromises(tempId).then(setViews);
  }

  async function loadPremiumViews(tempId: number) {
    const componentPromises = async (tempId: number) => {
      const PremiumView = await importView(tempId);
      const naid: string = nanoid(10);
      return <PremiumView key={naid} editMode={true} premium={true} />;
    };
    componentPromises(tempId).then(setPremiumViews);
  }

  const handleToggleChange = (e: any) => {
    setChecked(e.target.checked);
    setPlanName(e.target.value)
    setPaymentStatus(e.target.checked);
  };

  const handleBasicPlan = async () => {
    dispatch(
      templateUserDataRedux.actions.setTemplateUserData({
        ...templateUserData,
        premium_plan: false,
      })
    );
    setPaymentStatus(false);
    await addTemplateUserData({
      ...templateUserData,
      id: templateUserData.id,
      status: "Published",
      logoShape: templateUserData?.logoShape,
      premium_plan: false,
      firstName: firstCharCapital(templateUserData?.firstName),
      lastName: firstCharCapital(templateUserData?.lastName),
      selectedTemplateID: templateId
        ? templateId
        : templateUserData?.selectedTemplateID,
      profile_image: uploadProfileImageFile
        ? uploadProfileImageFile
        : templateUserData?.profile_image?.filename_disk,
      company_logo: uploadCompanyLogoFile
        ? uploadCompanyLogoFile
        : templateUserData?.company_logo?.filename_disk,
      templateColor: templateUserData?.templateColor,
      templateSecondaryColor: templateUserData?.templateSecondaryColor
        ? templateUserData?.templateSecondaryColor
        : "#f4f7fc",
      croppedAreaLogo: templateUserData.croppedAreaLogo,
      croppedAreaProfile: templateUserData.croppedAreaProfile,
    })
      .then(async (res) => {
        setLoading(false);
        dispatch(
          templateUserDataRedux.actions.setTemplateUserData({
            ...templateUserData,
            status: "Published",
            templateColor: templateUserData?.templateColor,
            templateSecondaryColor: templateUserData?.templateSecondaryColor,
          })
        );
        dispatch(templateUserDataRedux.actions.unsetFromEdit());
        setTimeout(() => {
          navigate(`/profile/preview`);
        }, 300);
      })
      .catch((e) => {
        setLoading(false);
        dispatch(templateUserDataRedux.actions.unsetFromEdit());
      });
  };

  const handlePremiumPlan = async () => {
    setPaymentStatus(true);
    navigate("/razorpay", { state: { planName: 'Premium', profileImage: uploadProfileImageFile, companyLogo: uploadCompanyLogoFile }});
  };

  return (
    <>
      <div className="plans">
        <div className="plans-cards">
          <section className="pricing pt-4 pb-lg-5 ">
            <div className="text-center">
              <h3 style={{ color: "CaptionText" }} className="gradient-text fw-bold">
                Our pricing plans
              </h3>
            </div>
            <div className="mt-5 container">
              {width < 992 ? (
                <MobileViewPlans
                  SwitchValue={planName}
                  checked={checked}
                  handleBasicPlan={() => handleBasicPlan()}
                  handlePremiumPlan={() => handlePremiumPlan()}
                  handleToggleChange={(e) => handleToggleChange(e)}
                  views={views}
                  plans={plans}
                  premiumViews={premiumViews}
                />
              ) : (
                <DesktopViewPlans
                  handleBasicPlan={() => handleBasicPlan()}
                  handlePremiumPlan={() => handlePremiumPlan()}
                  handleToggleChange={(e) => handleToggleChange(e)}
                  views={views}
                  plans={plans}
                  premiumViews={premiumViews}
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Plans;
