import AOS from "aos";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../../node_modules/aos/dist/aos.css";
import Skeleton from "react-loading-skeleton";


import Buttons from "../Buttons";
import { useEffect, useState } from "react";
AOS.init();

function Digitalcard() {

  const { auth, templateUserData } = useSelector((state: any) => state);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000)
    return () => clearTimeout(timer);
  }, [])
  return (
    <>
      <div
        id="home"
        className="business-card-section d-flex align-items-center ornament-bg gradient-background"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-2 order-lg-1" data-aos="fade-up">
              <div className="content-cols">
                {loading ? (
                  <Skeleton height={80} width={400} count={3} baseColor="#ebebeb" highlightColor="#d3d3d3 "  />
                ) :
                  <h1 className="heading-main mb-40 font-600">
                    Make Your <br /> Digital Business <br /> Cards
                  </h1>
                }
                {loading ? (
                  <Skeleton height="20px" count={3} style={{ marginTop: "30px" }} />
                ) : <p>
                  Select templates and customize them using our helpful design
                  themes. Send your professional card via email, share on social
                  media etc., and make a solid professional network.
                </p>}
                {loading ? (
                  <Skeleton height={60} width={150} style={{ marginTop: "90px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 "/>
                ) :
                  <Link
                    to={
                      auth.isAuthorized
                        ? `/edit-template/${templateUserData?.templateUserData?.selectedTemplateID}`
                        : "/auth/login"
                    }
                  >

                    <Buttons
                      btnText={"Create Now"}
                      buttonName={"button"}
                      className=""
                    ></Buttons>
                  </Link>
                }
              </div>
            </div>
            {loading ? (
              <Skeleton height={500} width={350} style={{ marginTop: "90px",position:"absolute",left:"70%" }} />
            ) :
              <div
                className="col-lg-6 text-center text-lg-end order-1 order-lg-2 pt-lg-4"
                data-aos="fade-left"
              >
                <div className="img">
                  <img
                    src="assets/images/card-img.png"
                    alt="battery100"
                    width="604"
                    height="732"
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Digitalcard;
